export default class AbstractAutocomplete {
    /**
     * @type Tagify
     */
    tagifyObject
    dataSource
    id

    constructor(id, dataSource, tagifyObject) {
        this.id = id
        this.dataSource = dataSource
        this.tagifyObject = tagifyObject
    }

    /**
     * Remove all selected values
     */
    clear() {
        this.tagifyObject.removeAllTags()
        this.tagifyObject.whitelist = null
        this.tagifyObject.dropdown.hide(true)
    }

}
