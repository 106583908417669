(function (window, document) {

    /***********************************************
     * Funcoes Javascript que fazem popup de um balao flutuante
     * Uso:  Incluir dentro do objeto as declaracoes
     *         onMouseover="ddrivetip('Qualque texto', 250);"
     *         onMouseout="hideddrivetip();"
     * paramentros: texto, tamanho vertical, cor
     * Partes herdadas de:
     * Cool DHTML tooltip script II- ? Dynamic Drive DHTML code library (www.dynamicdrive.com)
     * This notice MUST stay intact for legal use
     * Visit Dynamic Drive at http://www.dynamicdrive.com/ for full source code
     *
     *
     *PS: Melhor colocar no header, depois gera um deslocamento vertical variavel
     *******************************************************************************/

    var offsetfromcursorX     = 12; //Customize x offset of tooltip
    var offsetfromcursorY     = 10; //Customize y offset of tooltip
    var offsetdivfrompointerX = 10; //Customize x offset of tooltip DIV relative to pointer image
    var offsetdivfrompointerY = 14; /* Customize y offset of tooltip DIV relative to pointer image.
Tip: Set it to (height_of_pointer_image-1). */

    var tooltip_ie = (navigator.appName == "Microsoft Internet Explorer"?true:false);
    var tooltip_ns6 = document.getElementById && !document.all;
    var tooltip_enabletip  = false;
    var tooltip_inner_tipobj = null;
    var tooltip_inner_pointerobj = null;

    function tooltip_tipobj() {
        if(tooltip_inner_tipobj == null) {
            tooltip_inner_tipobj = document.getElementById("dhtmltooltip");
        }
        return tooltip_inner_tipobj;
    }
    function tooltip_pointerobj() {
        if(tooltip_inner_pointerobj == null) {
            tooltip_inner_pointerobj = document.getElementById("dhtmlpointer");
        }
        return tooltip_inner_pointerobj;
    }

    function ietruebody(){
        return ((document.compatMode && document.compatMode!="BackCompat") ? document.documentElement : document.body);
    }

    function ddrivetip(thetext, thewidth, thecolor) {
        if(typeof thewidth != "undefined") {
            tooltip_tipobj().style.width = thewidth+"px";
        }
        if(typeof thecolor != "undefined" && thecolor != "") {
            tooltip_tipobj().style.backgroundColor=thecolor;
        }
        tooltip_tipobj().innerHTML = thetext;
        tooltip_enabletip = true;
        return false;
    }

    function positiontip(e) {
        if(tooltip_enabletip == undefined) {
            tooltip_enabletip = false;
        }
        if(tooltip_enabletip) {
            var nondefaultpos = false
            var curX = (!tooltip_ie ? e.pageX : event.x+ietruebody().scrollLeft);
            var curY = (!tooltip_ie ? e.pageY : event.y+ietruebody().scrollTop);

            //Find out how close the mouse is to the corner of the window
            var winwidth   = (tooltip_ie && !window.opera ? ietruebody().clientWidth                  : window.innerWidth-20);
            //var winheight  = (tooltip_ie && !window.opera ? ietruebody().clientHeight                 : window.innerHeight-20);
            var winheight = 1000;
            var rightedge  = (tooltip_ie && !window.opera ? winwidth-event.clientX-offsetfromcursorX  : winwidth-e.clientX-offsetfromcursorX);
            var bottomedge = (tooltip_ie && !window.opera ? winheight-event.clientY-offsetfromcursorY : winheight-e.clientY-offsetfromcursorY);
            var leftedge   = (offsetfromcursorX < 0 ? offsetfromcursorX*(-1) : -1000);

            console.log ('curX:' + curX + ' , curY:' + curY
                +', winwidth:' + winwidth + ', winheight:' + winheight
                +', rightedge:' + rightedge + ', bottomedge:' + bottomedge
                +', leftedge:' + leftedge);

            var tipobj = tooltip_tipobj();
            var pointerobj = tooltip_pointerobj();
            //if the horizontal distance isn't enough to accomodate the width of the context menu
            if(rightedge<tipobj.offsetWidth) {
                /* box */
                tipobj.style.left     = curX - tipobj.offsetWidth     +"px";
                pointerobj.style.top  = curY - offsetfromcursorY      +"px";
                pointerobj.style.left = curX + offsetfromcursorX - 31 +"px";
            }
            else if(curX<leftedge) {
                tipobj.style.left     = "5px";
            }
            else {
                tipobj.style.left       = curX + offsetfromcursorX - offsetdivfrompointerX+"px";
                pointerobj.style.left   = curX + offsetfromcursorX+"px";
                tipobj.style.visibility = "visible";
            }
            /* hint aparece sobre o link */
            if(bottomedge < tipobj.offsetHeight) {
                /* box */
                tipobj.style.top = curY - offsetfromcursorY - offsetdivfrompointerY - tipobj.offsetHeight + 15 +"px";
                /* arrow */
                pointerobj.style.top = curY - offsetfromcursorY +"px";
                /* direção da arrow */
                if(rightedge >= tipobj.offsetWidth) {document.getElementById('dhtmlpointer').src='imagens/tooltip/arrow2.gif';}
                else 								  {document.getElementById('dhtmlpointer').src='imagens/tooltip/arrow4.gif';}
                /* hit aparece sob o link */
            } else {
                tipobj.style.top     = curY + offsetfromcursorY + offsetdivfrompointerY +"px";
                pointerobj.style.top = curY + offsetfromcursorY +"px";
                /* direção da arrow */
                if(rightedge >= tipobj.offsetWidth) {document.getElementById('dhtmlpointer').src='imagens/tooltip/arrow1.gif';}
                else 								  {document.getElementById('dhtmlpointer').src='imagens/tooltip/arrow3.gif';}
            }

            tipobj.style.visibility                          = "visible";
            if(!nondefaultpos) { pointerobj.style.visibility = "visible"; }
            else               { pointerobj.style.visibility = "hidden";  }
        }
    }

    function hideddrivetip(){
        if (tooltip_ns6||tooltip_ie){
            if (tooltip_tipobj() == undefined ) {
                return false;
            }
            if (tooltip_tipobj().style == undefined ) {
                return false;
            }
            tooltip_enabletip=false
            tooltip_tipobj().style.visibility="hidden";
            tooltip_pointerobj().style.visibility="hidden";
            tooltip_tipobj().style.left="-1000px";
            tooltip_tipobj().style.backgroundColor='';
            tooltip_tipobj().style.width='';
        }
    }

    /* Inicializacao, captura todos os onmouseover */
    document.onmousemove = positiontip;
    hideddrivetip();
    window.ddrivetip = ddrivetip;
    window.hideddrivetip = hideddrivetip;
})(window, document);