(function (window, $) {
    function PrevisualizacaoDocumento(parent, seletor, bolDraggable, iframeHeight, callbackFecharIframe) {
        this.parent = parent;
        this.seletor = seletor;
        this.bolDraggable = (typeof bolDraggable !== 'undefined'? bolDraggable : true);
        this.iframeHeight = iframeHeight || 500;
        this.callbackFecharIframe = callbackFecharIframe;
        this.container = $('#divBoxPreview');
        this.iframe = $('#fraBoxPreview');
        this.botaoFechar = $('#divClosePreview');
        this.timer = null;
    }

    PrevisualizacaoDocumento.prototype.mostrarDocumento = function (elementSelector, href) {
        var self = this;
        var promise = $.Deferred();
        var delay = 1000;
        var element = $(elementSelector), url;
        if (href){
            url = href;
        }
        else if (element.attr('hrefPreview') === undefined) {
            url = element.attr('href');
        } else {
            url = element.attr('hrefPreview');
        }
        var mimetype = element.attr('data-mimetype');
        if (url === null || url === undefined) {
            return;
        }

        self.iframe.on('load error abort beforeunload unload', function (ev) {
            ev.type === 'load' && promise.resolve();
            ev.type !== 'load' && promise.reject(ev);
        });

        if (mimetype !== '' && $.inArray(mimetype, ['mp3', 'wmv', 'MPG', 'wma', 'mp4', 'zip/xml', 'zip', 'mpeg', 'wav']) >= 0) {
            return;
        }

        if (self.bolDraggable) {
            self.container.css({
                'left': infraLerCookie('posleftboxpreview') + 'px',
                'top': infraLerCookie('postopboxpreview') + 'px',
                'width': infraLerCookie('larguraboxpreview') ? (infraLerCookie('larguraboxpreview') + 'px') : '50%',
                'position': 'fixed',
                'margin': 'auto',
                'height': infraLerCookie('alturaboxpreview') ? (infraLerCookie('alturaboxpreview') + 'px') : '50%'
            });
            self.iframe.css({
                'height': '99%'
            });

            if (infraLerCookie('posleftboxpreview') == null && infraLerCookie('postopboxpreview') == null) {
                self.container.css({
                    'top': '50%',
                    'left': '50%',
                    'transform': 'translate(-50%, -50%)'
                })
            }
        } else {
            self.container.css({
                'width': '100%',
                'position': 'relative'
            });
            self.iframe.css({
                'height': self.iframeHeight + 'px'
            });
        }

        self.timer = setTimeout(function () {
            self.container.fadeIn('fast', 'linear', function () {
                $('#linkOpenNew').attr('href', url);
                self.iframe.attr('src', url);
                if (self.bolDraggable) {
                    self.container.resizable({
                        start: function () {
                            $('.frameOverlay').fadeIn('fast');
                        },
                        stop: function (e, ui) {
                            $('.frameOverlay').fadeOut('fast');
                            infraCriarCookie('larguraboxpreview', ui.size.width, 365);
                            infraCriarCookie('alturaboxpreview', ui.size.height, 365);
                        }
                    });
                    self.container.draggable({
                        start: function () {
                            self.container.prop('style', false);
                            self.container.css({
                                'display': 'block',
                                'width': infraLerCookie('larguraboxpreview') + 'px',
                                'height': infraLerCookie('alturaboxpreview') + 'px'
                            });
                            $('.frameOverlay').fadeIn('fast');
                        },
                        stop: function (event, ui) {
                            $('.frameOverlay').fadeOut('fast');
                            infraCriarCookie('postopboxpreview', ui.position.top, 365);
                            infraCriarCookie('posleftboxpreview', ui.position.left, 365);
                        },
                        containment: 'window',
                        scroll: false,
                        cancel: 'div.btnPreview'
                    });
                }
            })
        }, delay);

        return promise;
    }

    PrevisualizacaoDocumento.prototype.iniciar = function () {
        var self = this;
        var parent = self.parent;
        var seletor = self.seletor;

        parent.on('mouseenter', seletor, function () {
            self.mostrarDocumento(this);
        });

        parent.on('mouseleave', seletor, function () {
            clearTimeout(self.timer);
        });

        function fecharVisualizacao() {
            self.container.fadeOut('fast', 'linear', function () {
                self.iframe.prop('src', undefined);
                clearTimeout(self.timer);
                self.callbackFecharIframe && self.callbackFecharIframe();
            });
        }

        parent.on('mousedown', seletor, fecharVisualizacao);

        self.botaoFechar.on('click', fecharVisualizacao);

        if (self.bolDraggable) {
            self.botaoFechar.on('mouseover', function () {
                self.timer = setTimeout(fecharVisualizacao, 500);
            });
        }

        self.botaoFechar.on('mouseleave', function () {
            clearTimeout(self.timer);
        });

        $('#linkOpenNew').on('click', fecharVisualizacao);

        $(window.document).on('mousedown keydown', function (e) {
            const ESC = 27
            if (e.which === ESC) {
                fecharVisualizacao();
            }
        });
    }

    window.PrevisualizacaoDocumento = PrevisualizacaoDocumento;
})(window, $ || jQuery);