import AbstractAutocomplete from "./AbstractAutocomplete";
import {reformatWhitelist} from "../phphelpers/autocomplete";

export default class Autocomplete extends AbstractAutocomplete {

    /**
     * Gets the current value
     * or sets it if a parameter is passed.
     * @param value
     */
    value(value) {
        if (value !== undefined) {
            this.#setValue(value)
        } else {
            return this.#getValue()
        }
    }

    #setValue(value) {
        let values = [value]
        const length = values.length;
        const newValues = reformatWhitelist(this.dataSource, values);
        this.tagifyObject.whitelist = newValues;
        this.tagifyObject.addTags(newValues.slice(0, length));
        this.tagifyObject.whitelist = null
    }

    #getValue() {
        const cleanValue = this.tagifyObject.getCleanValue()
        if (cleanValue.length) {
            return cleanValue[0].data
        }
        return null
    }
}
