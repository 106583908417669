import "core-js";
import "regenerator-runtime/runtime";

require('./polyfills');
require('./jquery.js')
require('./lib-ui');

import eproc from './eproc'

window.debounce = require('lodash.debounce');
window.throttle = require('lodash.throttle');
window.eproc = eproc;


import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';

window.bsCustomFileInput = bsCustomFileInput;

require('./eproc.jstree');
require('../../infra_js/multiple-select/multiple-select.js');
require('../../infra_js/ddslick/jquery.ddslick.min.js');
require('./script-especifico-bootstrap');
require('./funcoes');
require('./funcoes_eproc_int');
require('./tooltip');
require('./log');
require('./previsualizacao_documentos');