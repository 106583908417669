import IMask from "imask";
import UI from '../../../vendor/trf4/ui/showcase/resources/renderers/bootstrap4/js/UI';


UI.defaults.fetchErrorHandler = async (response) => {

    if (response instanceof DOMException && response.name === 'AbortError') {
        console.warn('request aborted by user')
        return
    }

    let errorMsg, details

    if (response instanceof Response) {
        errorMsg = response.statusText
        details = await response.text()
    } else if (response instanceof Error) {
        errorMsg = response.stack
        details = response.stack
    }

    eproc.toast.error(errorMsg);
    console.error(details)
}

//TODO remover essa sobrescrita de m�todo assim que a UI for inclu�da usando o reposit�rio pr�prio
UI.PHPHelper.time.init = function (id, amPm, value) {

    const input = $('#' + id);

    let options = amPm ? {format: 'HH:mm A'} : {format: 'HH:mm'};

    const format = UI.defaults.hourMinFormat;

    if (value) {
        options.defaultDate = moment(value, format);
    }

    input.datetimepicker(options);

    const blocks = {
        HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23
        },
        mm: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59
        }
    };

    const placeholder = input.attr('placeholder');

    function newMask(lazy) {
        return IMask(document.getElementById(id), {
            mask: Date,
            pattern: format,
            lazy: lazy,
            format: function (date) {
                return moment(date).format(format);
            },
            parse: function (str) {
                return moment(str, format);
            },
            blocks: blocks
        });
    }

    let momentMask = newMask(true);


    input.on('click', function (e) {
        momentMask.destroy();
        momentMask = newMask(false);
    });

    input.on('focusout', function (e) {
        momentMask.destroy();
        momentMask = newMask(true);
        input.attr('placeholder', placeholder);
    });

    input.on('change.datetimepicker', function (e) {
        momentMask.updateValue();
        return false;
    });
};

// ini fix tempor�rio ui#113 / eproc#1398

function postInitDatetimepicker(id) {
    const jqEl = $("#" + id)
    const parentModalContent = jqEl.closest('.modal-content')
    const modal = jqEl.closest('.modal')
    const isDatetimepickerInModal = (modal.length > 0);

    if (isDatetimepickerInModal) {
        jqEl.datetimepicker('widgetParent', parentModalContent)

        jqEl.on('show.datetimepicker', function (d) {
            modal.addClass('ui-has-open-datetimepicker')
            let top = jqEl.offset().top - parentModalContent.offset().top
            const left = jqEl.offset().left - parentModalContent.offset().left
            const datepicker = parentModalContent.find('.bootstrap-datetimepicker-widget:last');

            if (datepicker.hasClass('bottom')) {
                top += $(this).outerHeight();
            } else if (datepicker.hasClass('top')) {
                top -= datepicker.outerHeight();
            }

            datepicker.css({
                'top': top + 'px',
                'bottom': 'auto',
                'left': left + 'px'
            });

        });

        jqEl.on('hide.datetimepicker', d => {
            modal.removeClass('ui-has-open-datetimepicker')
        });
    }
}

const originalTimeInit = UI.PHPHelper.time.init
const originalDateInit = UI.PHPHelper.date.init

UI.PHPHelper.time.init = function (id, amPm, value) {
    originalTimeInit(id, amPm, value)
    postInitDatetimepicker(id)
}
UI.PHPHelper.date.init = function (id, amPm, value) {
    originalDateInit(id, amPm, value)
    postInitDatetimepicker(id)
}
// fim fix tempor�rio ui#113 / eproc#1398

window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.moment = require('moment');
window.moment.locale('pt-br');
window.IMask = IMask;
window.UI = UI;

///bootstrap4
require('bootstrap');
require('../../../vendor/trf4/ui/showcase/resources/renderers/bootstrap4/js/_tempusdominus.js');
require('../../../vendor/trf4/ui/showcase/resources/renderers/bootstrap4/js/_bootstrap-select.js');
require('../../../vendor/trf4/ui/showcase/resources/renderers/bootstrap4/js/_range.js');

require('bootstrap-fileinput/js/plugins/piexif.js');
require('bootstrap-fileinput/js/plugins/sortable.js');
require('bootstrap-fileinput');
require('bootstrap-fileinput/themes/explorer-fas/theme.js');
require('bootstrap-fileinput/themes/fas/theme.js');
require('bootstrap-fileinput/js/locales/pt-BR.js');

window.Tagify = require('@yaireo/tagify');
window.DragSort = require('@yaireo/dragsort');

function enableFormValidation(form) {
    form.addEventListener('submit', function (event) {
        form.classList.add('was-validated');
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }, false);
}

window.enableFormValidation = enableFormValidation;

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    'use strict';
    window.addEventListener('load', function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            enableFormValidation(form);
        });
    }, false);
})();
