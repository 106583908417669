(function () {
    'use strict';

    window.addEventListener('load', function () {

        const rangeInputs = document.querySelectorAll('input[type="range"]');

        rangeInputs.forEach(function (input) {
            input.addEventListener('input', onInput);
            onInput.call(input);
        });

        function onInput() {
            const input = this,
                controlMin = parseFloat(input.min),
                controlMax = parseFloat(input.max),
                controlVal = parseFloat(input.value),
                controlThumbWidth = parseFloat(input.dataset.thumbwidth) - 4,
                valueRange = controlMax - controlMin,
                position = ((controlVal - controlMin) / valueRange) * 100,
                positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2),
                parent = input.parentNode,
                selection = parent.querySelector('.progress-bar')

            updateControls();
            updateSelectionBar();

            ////////////////
            ////////////////
            ////////////////
            ////////////////
            ////////////////

            function updateControls() {
                const
                    output = input.nextElementSibling,
                    otherOutputId = output.dataset.baseId + '_output_' + (output.dataset.index === '0' ? '1' : '0'),
                    otherOutput = document.getElementById(otherOutputId);

                if (otherOutput) {
                    const isCurrentOutputBelow = output.classList.contains('below');
                    const isOtherOutputBelow = otherOutput.classList.contains('below');
                    const outputsTooClose = hasPositionOverlap(output, otherOutput);

                    if (outputsTooClose && isCurrentOutputBelow === isOtherOutputBelow || !outputsTooClose && isCurrentOutputBelow !== isOtherOutputBelow) {
                        otherOutput.classList.toggle('below', !isOtherOutputBelow);
                    }
                }

                output.style.left = 'calc(' + position + '% - ' + positionOffset + 'px)';
                output.style.visibility = 'visible';
                output.textContent = controlVal;
                selection.style.width = position + '%';
            }

            function updateSelectionBar() {

                const slides = parent.getElementsByTagName("input");
                const slide1 = parseFloat(slides[0].value);
                const isMultiple = typeof slides[1] !== "undefined";

                let selectionWidth = null;

                if (isMultiple) {
                    const slide2 = parseFloat(slides[1].value);
                    const [selectionLeft, newSelectionWidth] = getMultiRangeSelectionAttrs(
                        slide1,
                        slide2,
                        controlMin,
                        valueRange
                    );
                    selectionWidth = newSelectionWidth
                    selection.style.left = selectionLeft + '%';
                } else {
                    selectionWidth = ((slide1 - controlMin) / valueRange) * 100;
                }

                selection.style.width = selectionWidth + '%';
            }
        }
    });

    function getMultiRangeSelectionAttrs(slide1, slide2, controlMin, valueRange) {
        if (slide1 > slide2) {
            const tmp = slide2;
            slide2 = slide1;
            slide1 = tmp;
        }

        const left = ((slide1 - controlMin) / valueRange) * 100;
        const inputInterval = slide2 - slide1;
        const selectionWidth = (inputInterval / valueRange) * 100;
        return [left, selectionWidth]
    }

    function hasPositionOverlap(el1, el2) {
        const el1Rect = el1.getBoundingClientRect();
        const el2Rect = el2.getBoundingClientRect();
        return !((el2Rect.x > (el1Rect.x + el1Rect.width)) || (el2Rect.x + el1Rect.width) < el1Rect.x);
    }
})();
