"use strict";

import autocomplete from './phphelpers/autocomplete';
import dateInterval from './phphelpers/dateInterval';
import date from './phphelpers/date';
import time from './phphelpers/time';
import select from './phphelpers/select';
import addActivatedBy from './phphelpers/addActivatedBy';
import {DATA_ATTRIBUTES, isObject} from "./utils";


export const UI = {
    defaults: {
        locale: 'pt-br',
        dateHourMinFormat: 'DD/MM/YYYY HH:mm',
        dateFormat: 'DD/MM/YYYY',
        hourMinFormat: 'HH:mm',
        defaultValueFormatFn: function (k, v) {
            return k ? k : (isObject(v) ? null : v);
        },
        defaultLabelFormatFn: function (k, v) {
            return isObject(v) ? Object.values(v).join(' - ') : v;
        },
        /**
         * @param {Response} response
         */
        fetchErrorHandler: (response) => {
            if (response instanceof TypeError){
                throw response
            }

            if (response.name !== 'AbortError'){
                throw Error(response.statusText)
            }

        },
        fetch: {
            /**
             *
             * @param {IDataSource} dataSource
             */
            injectHeaders: (dataSource) => {
            }
        }
    },
    PHPHelper: {
        autocomplete: autocomplete,
        date: date,
        dateInterval: dateInterval,
        select: select,
        time: time,
        addActivatedBy: addActivatedBy,
    },
    multiAutocomplete: {
        /**
         *
         * @param id
         * @returns MultiAutocomplete
         */
        get: (id) => {
            return $("#" + id).data(DATA_ATTRIBUTES.multiAutocomplete)
        }
    },

    autocomplete: {
        /**
         *
         * @param id
         * @returns Autocomplete
         */
        get: (id) => {
            return $("#" + id).data(DATA_ATTRIBUTES.autocomplete)
        }
    },
}

export default UI
