import moment from "moment";

export default {
    /**
     *
     * @param id string
     * @param withTime boolean
     * @param value = null string
     */
    init(id, withTime, value) {

        const input = $('#' + id);

        let options = withTime ? {} : {format: 'L'};

        const format = withTime ? UI.defaults.dateHourMinFormat : UI.defaults.dateFormat;

        if (value) {
            options.defaultDate = moment(value, format);
        }

        input.datetimepicker(options);

        const blocks = {
            DD: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31
            },
            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12
            },
            YYYY: {
                mask: IMask.MaskedRange,
                from: '0000',
                to: '9999'
            }
        };

        if (withTime) {
            blocks.HH = {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23
            };

            blocks.mm = {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59
            };
        }

        const placeholder = input.attr('placeholder');

        function newMask(lazy) {
            return IMask(document.getElementById(id), {
                mask: Date,
                pattern: format,
                lazy: lazy,
                format: function (date) {
                    return moment(date).format(format);
                },
                parse: function (str) {
                    return moment(str, format);
                },
                blocks: blocks
            });
        }

        let momentMask = newMask(true);
        const formatPlaceholder = format.replace(/[A-Z]/g, "_");

        input.on('click', function (e) {
            momentMask.destroy();
            momentMask = newMask(false);
            if (input.val() === formatPlaceholder) {
                input[0].setSelectionRange(0, 0);
            }
        });

        input.on('focusout', function (e) {
            momentMask.destroy();
            momentMask = newMask(true);
            input.attr('placeholder', placeholder);
        });

        input.on('change.datetimepicker', function (e) {
            if (input.val() === formatPlaceholder) {
                input.datetimepicker('clear');
                input.val('')
            }
            momentMask.updateValue();
            return false;
        });
    }
}
