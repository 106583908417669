import {shouldActivate} from "../utils";

export default (targetId, activatorId) => {
    const activator = document.getElementById(activatorId);
    const target = document.getElementById(targetId);
    const dataAttr = 'uiActivates';

    const isChecked = shouldActivate(activator);
    activator.dataset[dataAttr] = targetId;

    const handleChange = (e) => {
        const activator = e.target;
        const targetId = activator.dataset[dataAttr];
        const isChecked = shouldActivate(activator);
        const target = $('#' + targetId);
        checkActivation(target, isChecked);
    }

    const checkActivation = (target, isChecked) => {
        target.prop('disabled', !isChecked);
        target.selectpicker('refresh');
    }

    activator.addEventListener('change', handleChange);
    checkActivation($(target), isChecked);
}
