export function isObject(obj) {
    return obj != null && obj.constructor.name === "Object"
}

export function debounce(fn, wait) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            fn.apply(context, args);
        }, wait);
    };
}


/**
 * retorna valor do campo select|checkbox que ativa campo relacionado true|false, onde campo o preenchido retorna true
 */
export function shouldActivate(element) {
    if (element.tagName == 'SELECT') {
        return (element.value.length > 0);
    } else {
        return element.checked;
    }
}

export const DATA_ATTRIBUTES = {
    autocomplete:'UI-autocomplete-object',
    multiAutocomplete: 'UI-multi-autocomplete-object'
}
