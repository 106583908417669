export default CreateRequestDispatcher;

function CreateRequestDispatcher() {
    let controller


    /**
     *
     * @param {IDispatchOpts} opts
     */
    function dispatch(opts) {
        abort()
        controller = new AbortController();

        return doFetch(opts)
    }
    
    function abort(){
        controller && controller.abort()
    }


    /**
     *
     * @param {IDispatchOpts} opts
     */
    function doFetch(opts) {
        const {
            dataSource,
            componentParams,
            userCallbacks,
            userCatch
        } = opts
        const {url, options} = getFetchParams()

        try {
            const promise = fetch(url, options)
                .then(tryToCatchErrors)
                .then(response => response.json())
                .then(userCallbacks)

            if (userCatch) {
                promise.catch(userCatch)
            }

            promise.catch(UI.defaults.fetchErrorHandler)

            return promise
        } catch (err) {
            console.error(err)
            throw (err)
        }

        function getFetchParams() {
            const
                method = dataSource.method,
                params = dataSource.paramsFn ? dataSource.paramsFn() : {};

            let url,
                options = {
                    method: method.toUpperCase(),
                    signal: controller.signal
                },
                urlSearchParams = new URLSearchParams({
                    ...componentParams,
                    ...params
                })


            if (dataSource.method === 'post') {
                url = dataSource.url
                options.body = urlSearchParams
            } else {
                const querySeparator = (dataSource.url.indexOf('?') !== -1 ? '&' : '?')
                const valueString = urlSearchParams.toString() ? (querySeparator + urlSearchParams) : '';
                url = dataSource.url + valueString
            }


            const injectedHeaders = UI.defaults.fetch.injectHeaders(dataSource);

            options.headers = {
                ...options.headers,
                ...injectedHeaders,
            }

            return {url, options}
        }
    }

    /**
     * @param {Response} response
     * @returns {{ok}|*}
     */
    function tryToCatchErrors(response) {
        if (!response.ok) {
            return Promise.reject(response);
        }
        return response
    }

    return {
        dispatch: dispatch,
        abort: abort
    }
}
