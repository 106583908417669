var eprocJstree = (function($, undefined){
"use strict";

require('jstree');

var originalFnSearch = $.jstree.plugins.search;
$.jstree.defaults.core.strings = {
    'Loading ...': "Carregando árvore ..."
};

$.jstree.plugins.search = function (options, parent) {
    this.bind = function () {
        parent.bind.call(this);

        this._data.search.str = "";
        this._data.search.dom = $();
        this._data.search.res = [];
        this._data.search.opn = [];
        this._data.search.som = false;
        this._data.search.smc = false;
        this._data.search.hdn = [];

        this.element
            .on("search.jstree", $.proxy(function (e, data) {
                if(this._data.search.som && data.res.length) {
                    var m = this._model.data, i, j, p = [], k, l;
                    for(i = 0, j = data.res.length; i < j; i++) {
                        if(m[data.res[i]] && !m[data.res[i]].state.hidden) {
                            p.push(data.res[i]);
                            p = p.concat(m[data.res[i]].parents);
                            if(this._data.search.smc) {
                                for (k = 0, l = m[data.res[i]].children_d.length; k < l; k++) {
                                    if (m[m[data.res[i]].children_d[k]] && !m[m[data.res[i]].children_d[k]].state.hidden) {
                                        p.push(m[data.res[i]].children_d[k]);
                                    }
                                }
                            }
                        }
                    }
                    p = $.vakata.array_remove_item($.vakata.array_unique(p), $.jstree.root);
                    this._data.search.hdn = this.hide_all(true);
                    this.show_node(p, true);
                    this.redraw(true);
                }
            }, this))
            .on("clear_search.jstree", $.proxy(function (e, data) {
                if(this._data.search.som && data.res.length) {
                    this.show_node(this._data.search.hdn, true);
                    this.redraw(true);
                }
            }, this));
    };
    /**
     * used to search the tree nodes for a given string
     * @name search(str [, skip_async])
     * @param {String} str the search string
     * @param {Boolean} skip_async if set to true server will not be queried even if configured
     * @param {Boolean} show_only_matches if set to true only matching nodes will be shown (keep in mind this can be very slow on large trees or old browsers)
     * @param {mixed} inside an optional node to whose children to limit the search
     * @param {Boolean} append if set to true the results of this search are appended to the previous search
     * @plugin search
     * @trigger search.jstree
     */
    this.search = function (str, skip_async, show_only_matches, inside, append, show_only_matches_children) {
        if(str === false || $.trim(str.toString()) === "") {
            return this.clear_search();
        }
        inside = this.get_node(inside);
        inside = inside && inside.id ? inside.id : null;
        str = str.toString();
        var s = this.settings.search,
            a = s.ajax ? s.ajax : false,
            m = this._model.data,
            f = null,
            r = [],
            p = [], i, j;
        if(this._data.search.res.length && !append) {
            this.clear_search();
        }
        if(show_only_matches === undefined) {
            show_only_matches = s.show_only_matches;
        }
        if(show_only_matches_children === undefined) {
            show_only_matches_children = s.show_only_matches_children;
        }
        if(!skip_async && a !== false) {
            if($.isFunction(a)) {
                return a.call(this, str, $.proxy(function (d) {
                    if(d && d.d) { d = d.d; }
                    this._load_nodes(!$.isArray(d) ? [] : $.vakata.array_unique(d), function () {
                        this.search(str, true, show_only_matches, inside, append, show_only_matches_children);
                    });
                }, this), inside);
            }
            else {
                a = $.extend({}, a);
                if(!a.data) { a.data = {}; }
                a.data.str = str;
                if(inside) {
                    a.data.inside = inside;
                }
                if (this._data.search.lastRequest) {
                    this._data.search.lastRequest.abort();
                }
                this._data.search.lastRequest = $.ajax(a)
                    .fail($.proxy(function () {
                        this._data.core.last_error = { 'error' : 'ajax', 'plugin' : 'search', 'id' : 'search_01', 'reason' : 'Could not load search parents', 'data' : JSON.stringify(a) };
                        this.settings.core.error.call(this, this._data.core.last_error);
                    }, this))
                    .done($.proxy(function (d) {
                        if(d && d.d) { d = d.d; }
                        this._load_nodes(!$.isArray(d) ? [] : $.vakata.array_unique(d), function () {
                            this.search(str, true, show_only_matches, inside, append, show_only_matches_children);
                        });
                    }, this));
                return this._data.search.lastRequest;
            }
        }
        if(!append) {
            this._data.search.str = str;
            this._data.search.dom = $();
            this._data.search.res = [];
            this._data.search.opn = [];
            this._data.search.som = show_only_matches;
            this._data.search.smc = show_only_matches_children;
        }

        f = new $.vakata.search(str, true, { caseSensitive : s.case_sensitive, fuzzy : s.fuzzy });
        $.each(m[inside ? inside : $.jstree.root].children_d, function (ii, i) {
            var v = m[i];
//                    Codigo abaixo comentado para incluir as linhas necessárias para eproc
//                     if(v.text && !v.state.hidden && (!s.search_leaves_only || (v.state.loaded && v.children.length === 0)) && ( (s.search_callback && s.search_callback.call(this, str, v)) || (!s.search_callback && f.search(v.text).isMatch) ) ) {
//                         r.push(i);
//                         p = p.concat(v.parents);
//                     }
//                    fim do código comentado
//                    inicio do codigo customizado eproc 1:2
//                    Trecho de código próprio para utilizar na árvore de documentos
            var textoCheio = v.text;
            if (v.id != 'capa') {
                if (v.li_attr.evento == 'true') {
                    //Adicionada verificacao pra que jsTree possa ser utilizada em outros pontos do sistema que nao apenas a
                    //árvore de documentos
                    if (document.getElementById('evento_' + v.id)) {
                        v.text = document.getElementById('evento_' + v.id).innerHTML;
                    }
                } else {
                    //Adicionada verificacao pra que jsTree possa ser utilizada em outros pontos do sistema que nao apenas a
                    //árvore de documentos
                    if (document.getElementById('spnEventoDocumento_' + v.id)) {
                        v.text = document.getElementById('spnEventoDocumento_' + v.id).innerHTML;
                    }
                }
            }
            // Fim do código próprio
            if(v.text && (!s.search_leaves_only || (v.state.loaded && v.children.length === 0)) && ( (s.search_callback && s.search_callback.call(this, str, v)) || (!s.search_callback && f.search(v.text).isMatch) ) ) {
                r.push(i);
                p = p.concat(v.parents);
            }
            // Seta de volta as info do texto para não dar problema por ter alterado o valor do objeto da árvore.
            v.text = textoCheio;
//                    fim do codigo customizado eproc 1:2

        });
        if(r.length) {
            p = $.vakata.array_unique(p);
            for(i = 0, j = p.length; i < j; i++) {
                if(p[i] !== $.jstree.root && m[p[i]] && this.open_node(p[i], null, 0) === true) {
                    this._data.search.opn.push(p[i]);
                }
            }
            if(!append) {
                this._data.search.dom = $(this.element[0].querySelectorAll('#' + $.map(r, function (v) { return "0123456789".indexOf(v[0]) !== -1 ? '\\3' + v[0] + ' ' + v.substr(1).replace($.jstree.idregex,'\\$&') : v.replace($.jstree.idregex,'\\$&'); }).join(', #')));
                this._data.search.res = r;
            }
            else {
                this._data.search.dom = this._data.search.dom.add($(this.element[0].querySelectorAll('#' + $.map(r, function (v) { return "0123456789".indexOf(v[0]) !== -1 ? '\\3' + v[0] + ' ' + v.substr(1).replace($.jstree.idregex,'\\$&') : v.replace($.jstree.idregex,'\\$&'); }).join(', #'))));
                this._data.search.res = $.vakata.array_unique(this._data.search.res.concat(r));
            }
            this._data.search.dom.children(".jstree-anchor").addClass('jstree-search');
        }
        /**
         * triggered after search is complete
         * @event
         * @name search.jstree
         * @param {jQuery} nodes a jQuery collection of matching nodes
         * @param {String} str the search string
         * @param {Array} res a collection of objects represeing the matching nodes
         * @plugin search
         */
        this.trigger('search', { nodes : this._data.search.dom, str : str, res : this._data.search.res, show_only_matches : show_only_matches });
        //                    inicio do codigo customizado eproc 2:2
        return r; // sim, essa linha é necessária do eproc e não estava no plugin
        //                    fim do codigo customizado eproc 2:2
    };
    /**
     * used to clear the last search (removes classes and shows all nodes if filtering is on)
     * @name clear_search()
     * @plugin search
     * @trigger clear_search.jstree
     */
    this.clear_search = function () {
        if(this.settings.search.close_opened_onclear) {
            this.close_node(this._data.search.opn, 0);
        }
        /**
         * triggered after search is complete
         * @event
         * @name clear_search.jstree
         * @param {jQuery} nodes a jQuery collection of matching nodes (the result from the last search)
         * @param {String} str the search string (the last search string)
         * @param {Array} res a collection of objects represeing the matching nodes (the result from the last search)
         * @plugin search
         */
        this.trigger('clear_search', { 'nodes' : this._data.search.dom, str : this._data.search.str, res : this._data.search.res });
        if(this._data.search.res.length) {
            this._data.search.dom = $(this.element[0].querySelectorAll('#' + $.map(this._data.search.res, function (v) {
                return "0123456789".indexOf(v[0]) !== -1 ? '\\3' + v[0] + ' ' + v.substr(1).replace($.jstree.idregex,'\\$&') : v.replace($.jstree.idregex,'\\$&');
            }).join(', #')));
            this._data.search.dom.children(".jstree-anchor").removeClass("jstree-search");
        }
        this._data.search.str = "";
        this._data.search.res = [];
        this._data.search.opn = [];
        this._data.search.dom = $();
    };

    this.redraw_node = function(obj, deep, callback, force_render) {
        obj = parent.redraw_node.apply(this, arguments);
        if(obj) {
            if($.inArray(obj.id, this._data.search.res) !== -1) {
                var i, j, tmp = null;
                for(i = 0, j = obj.childNodes.length; i < j; i++) {
                    if(obj.childNodes[i] && obj.childNodes[i].className && obj.childNodes[i].className.indexOf("jstree-anchor") !== -1) {
                        tmp = obj.childNodes[i];
                        break;
                    }
                }
                if(tmp) {
                    tmp.className += ' jstree-search';
                }
            }
        }
        return obj;
    };
};

    return $.jstree;
}($));

module.exports = eprocJstree;