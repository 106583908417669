export default {
    /**
     *
     * @param startId
     * @param endId
     * @param withTime
     * @param opts
     */
    init(startId, endId, withTime, opts) {
        const startValue = opts.startValue || null
        const endValue = opts.endValue || null
        UI.PHPHelper.date.init(startId, withTime, startValue);
        UI.PHPHelper.date.init(endId, withTime, endValue);

        const dateStart = $('#' + startId);
        const dateEnd = $('#' + endId);

        dateStart.on("change.datetimepicker", function (e) {
            const minDate = dateStart.val() ? e.date : false;
            dateEnd.datetimepicker('minDate', minDate);
        });

        dateEnd.on("change.datetimepicker", function (e) {
            const maxDate = dateEnd.val() ? e.date : false;
            dateStart.datetimepicker('maxDate', maxDate);
        });
    }
}
