import CreateRequestDispatcher from "../RequestDispatcher";

export default {
    init: (id, feedbackContainerHtml) => {
        const select = $('#' + id);
        select.selectpicker();
        select.parent().append(feedbackContainerHtml);
    },

    /**
     *
     * @param dependencyName string
     * @param inputId string
     * @param spinnerPlaceholder string
     * @param childId string
     * @param dataSource object {url, method, paramsFn}
     * @param {string|null} placeholder
     * @param placeholderIfNull
     * @param shouldTriggerSelectOnChange
     */
    addDependency: (
        dependencyName,
        inputId,
        spinnerPlaceholder,
        childId,
        dataSource,
        placeholder,
        placeholderIfNull,
        shouldTriggerSelectOnChange
    ) => {
        const jqInput = $('#' + inputId)
        const requestDispatcher = CreateRequestDispatcher()

        //todo formatar o resultado (nao posso garantir que vai ser sempre innerHTML, value e data

        //todo quem gera isso é o pai né... assim vai ter duplicatas no caso de mais de um elemento dependente
        function getSelectedOptionData(el) {
            var selectedOption = el.selectedOptions[0];

            return {
                innerHTML: selectedOption.innerHTML,
                value: selectedOption.value,
                data: selectedOption.dataset
            };
        }


        function setChildSelectDisabled(placeholder, child) {
            child.disabled = true;
            addOptionsToSelect(placeholder, child, []);
        }


        /**
         *
         * @param placeholder string|null
         * @param select
         * @param setOptions
         * @param dataAttributesMap
         * @param valueFormatFn function
         * @param labelFormatFn function
         */
        function addOptionsToSelect(
            placeholder,
            select,
            setOptions,
            dataAttributesMap,
            valueFormatFn,
            labelFormatFn
        ) {
            let optionsHTML = ''

            if (placeholder !== null) {
                optionsHTML = `<option value=''>${placeholder}</option>`;
            }

            setOptions.forEach(function (v, k) {
                const value = valueFormatFn(k, v)
                const innerHTML = labelFormatFn(k, v)
                const optionAttrsString = Object.getOwnPropertyNames(dataAttributesMap)
                    .map((returnAttr, optionAttr) => `${optionAttr}="${o[returnAttr]}"`)
                    .join(' ')

                optionsHTML += `<option value="${value}" ${optionAttrsString}>${innerHTML}</option>`
            });

            select.innerHTML = optionsHTML
            $("#" + select.id).selectpicker('refresh')
        }

        function clearSelect(el) {
            el.disabled = true;
            el.innerHTML = '';

            const jqEl = $("#" + el.id)
            jqEl.selectpicker('refresh');
            jqEl.trigger('change')
        }

        const showSpinner = (spinner) => {
            spinner.style = ''
        }
        const hideSpinner = (spinner) => {
            spinner.style = 'display:none;'
        }

        function clearFeedbackMessage(feedbackMessage) {
            feedbackMessage.html('');
            feedbackMessage.hide();
        }

        function setFeedbackMessage(feedbackMessage, message) {
            feedbackMessage.html(message);
            feedbackMessage.show();
        }

        jqInput.change(function () {
            requestDispatcher.abort()
            const select = this;
            const childEl = document.getElementById(childId);
            const feedbackMessage = $('#' + childEl.id + '-feedbackMessage')

            clearSelect(childEl);
            clearFeedbackMessage(feedbackMessage);

            const spinner = document.getElementById(spinnerPlaceholder)

            if (select.value) {
                showSpinner(spinner)
                const valueFormatFn = dataSource.valueFormatFn || UI.defaults.defaultValueFormatFn;
                const labelFormatFn = dataSource.labelFormatFn || UI.defaults.defaultLabelFormatFn
                const dataAttributesMap = dataSource.dataAttributesMap
                const onSuccess = function (data) {
                    hideSpinner(spinner);

                    childEl.disabled = false;

                    addOptionsToSelect(
                        placeholder,
                        childEl,
                        data,
                        dataAttributesMap,
                        valueFormatFn,
                        labelFormatFn
                    );

                    if (placeholder === null) {
                        $(childEl).trigger('change');
                    }
                }
                const onError = (error) => {
                    if (error.name === 'AbortError') {

                    } else {
                        hideSpinner(spinner);
                        setFeedbackMessage(feedbackMessage, error);
                        console.error(error);
                    }
                }

                const promise = requestDispatcher.dispatch({
                    dataSource: dataSource,
                    componentParams: {},
                    userCallbacks: onSuccess,
                    userCatch: onError,
                })

            } else {
                hideSpinner(spinner);
                setChildSelectDisabled(placeholderIfNull, childEl);
            }
        })

        if (shouldTriggerSelectOnChange) {
            jqInput.change();
        }
    },
}


