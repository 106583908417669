import {reformatWhitelist} from "../phphelpers/autocomplete";
import AbstractAutocomplete from "./AbstractAutocomplete";

export default class MultiAutocomplete extends AbstractAutocomplete {

    /**
     * Adds one or more values to the component.
     * If valueOrValues is
     * @param valueOrValues
     */
    add(valueOrValues) {
        let values = (Array.isArray(valueOrValues)) ? valueOrValues : [valueOrValues]

        const length = values.length;

        const newValues = reformatWhitelist(this.dataSource, [
            ...values,
            ...this.tagifyObject.whitelist.map(t => t.data)
        ]);

        this.tagifyObject.whitelist = newValues;
        this.tagifyObject.addTags(newValues.slice(0, length));
        this.tagifyObject.whitelist = null
    }


    /**
     * Retrieves all selected values
     * @returns {*}
     */
    values() {
        return this.tagifyObject.getCleanValue().map(v => v.data)
    }
}
