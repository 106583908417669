import moment from "moment";

export default {
    /**
     *
     * @param id string
     * @param amPm boolean
     * @param value = null string
     */
    init(id, amPm, value) {

        const input = $('#' + id);
        let options = {};
        options.format = (amPm ? 'HH:mm A' : 'HH:mm');

        const format = options.format;

        if (value) {
            options.defaultDate = moment(value, format);
        }

        input.datetimepicker(options);

        const blocks = {
            HH: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23
            },
            mm: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59
            }
        };

        const placeholder = input.attr('placeholder');

        function newMask(lazy) {
            return IMask(document.getElementById(id), {
                mask: Date,
                pattern: format,
                lazy: lazy,
                format: function (date) {
                    return moment(date).format(format);
                },
                parse: function (str) {
                    return moment(str, format);
                },
                blocks: blocks
            });
        }

        let momentMask = newMask(true);


        input.on('click', function (e) {
            momentMask.destroy();
            momentMask = newMask(false);
        });

        input.on('focusout', function (e) {
            momentMask.destroy();
            momentMask = newMask(true);
            input.attr('placeholder', placeholder);
        });

        input.on('change.datetimepicker', function (e) {
            momentMask.updateValue();
            return false;
        });
    }
}
